// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
// APIs services
import apiLibrary from "services/api";
// Assets
import uploadIcon from "assets/icons/upload.svg";
// Store utils
import { Dispatch, Key, useCallback, useEffect } from "react";
import { useDispatch } from "react-redux";
import { openAllLayersListingScreen, resetMap } from "store/geography";
// Third party services
import Dropzone from "react-dropzone";
import { FieldArray, Form, Formik, FormikProps } from "formik";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
// Icons
import ArrowLeftIcon from "assets/icons/HeroIcons/ArrowLeftIcon";

import CloudUploadIcon from "assets/icons/HeroIcons/CloudUploadIcon";
import TrashIcon from "assets/icons/HeroIcons/TrashIcon";
import handleShapeAndKMLTypeFiles from "Components/Geography/SideBar/utils/handleShapeAndKMLTypeFiles";
import ColorPicker from "Components/Geography/ColorPicker";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";
import usePermissions from "hooks/usePermissions";

interface Region {
  name: string;
  geoJSON: any; // Replace 'any' with the actual type of geoJSON
  description: string;
}
interface IRegionErrors {
  name: string | null;
  description: string | null;
  geoJSON: string | null;
}

const MAXIMUM_FILE_SIZE = 200; // kbs

// Schema

const validationSchema = Yup.object().shape({
  regions: Yup.array()
    .of(
      Yup.object().shape({
        name: Yup.string().required("Region name is required"),
        description: Yup.string()
          .max(255, "255 max characters")
          .required("description is required"),
        geoJSON: Yup.mixed().required("GeoJSON is required"),
      })
    )
    .required("Regions are required"),
  color: Yup.string().required("Fill Color is required"),
  borderColor: Yup.string().required("Border Color is required"),
});

interface UploadFormInterface {
  height: string;
}

const UploadForm = ({ height }: UploadFormInterface) => {
  const dispatch = useDispatch();
  const { communityId } = useParams();
  const initialValues: {
    regions: Region[];
    color: string;
    borderColor: string;
  } = {
    regions: [],
    color: "#FF0000",
    borderColor: "#FF0000",
  };

  useEffect(() => {
    // reset selected layers
    dispatch(resetMap(true));
  }, []);
  const {communities} = usePermissions();
  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };

  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    try {
      if (!willColorBeVisibleOnMap(values.color)) {
        setFieldError("color", "Please select a fill color that is easily visible");
        return;
      }
      if (!willColorBeVisibleOnMap(values.borderColor)) {
        setFieldError("borderColor", "Please select a border color that is easily visible");
        return;
      }

      const uploadPromises = values.regions.map(
        async (region: any, index: any) => {
          try {
            const mapData = await handleShapeAndKMLTypeFiles(region.geoJSON);

            const blob = new Blob([JSON.stringify(mapData)], {
              type: "application/json",
            });
            const file = new File([blob], "location.geojson", {
              type: "application/geo+json",
            });
            const { data } = await apiLibrary.file.fileUpload(
              file,
              true,
              "public",
              "region"
            );

            const updatedRegion: any = {
              description: region.description,
              geoFileId: data.id,
              type: "region",
              name: region.name,
              color: values.color,
              borderColor: values.borderColor,
            };

            return { status: "fulfilled", updatedRegion, geoFileId: data.id };
          } 
          catch (error: any) {
            if (error.code === "CRS_MISSING") {
              return {
                status: "rejected",
                reason: error.code,
                region,
                error: error,
                fieldIndex: index,
              };
            } else {
              return {
                status: "rejected",
                reason: null,
                region,
                error: error?.response?.data ?? error?.message,
                fieldIndex: index,
              };
            }
          }
        }
      );

      const uploadResults = await Promise.allSettled(uploadPromises);

      const failedUploads = uploadResults.filter(
        (result) =>
          result.status === "rejected" || result.value.status === "rejected"
      );

      if (failedUploads.length > 0) {
        let errorMessages: any = [];

        failedUploads.forEach((failedUpload: any, index: number) => {
          const { reason, error, region, fieldIndex } = failedUpload.value;
          const regionName = region?.name;
          const errorMessage = error?.message;
          const detailMessage = error?.details;

          if (reason === "CRS_MISSING") {
            setFieldError(`regions[${fieldIndex}].geoJSON`, detailMessage);
          } else if (errorMessage && regionName) {
            errorMessages.push(`Region: ${regionName}, Error: ${errorMessage}`);
          }
        });

        if (errorMessages.length > 0) {
          Toasts.error(errorMessages.join(", "));
        }
        return;
      }

      // Process successful uploads
      const successfulPayload = uploadResults
        .filter(
          (result: any) =>
            result.status === "fulfilled" && result.value.status === "fulfilled"
        )
        .map((result: any) => result.value.updatedRegion);
      // console.log("hellow " ,)
      const jsonString = JSON.stringify(successfulPayload);

      // Calculate the length of the string (in bytes)
      const sizeInBytes = new Blob([jsonString]).size;

      // Convert bytes to megabytes
      const sizeInMB = sizeInBytes / (1024 * 1024);

      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, {
          regions: successfulPayload,
          color: values.color,
          borderColor: values.borderColor,
          type: "region",
        });

        Toasts.success(`Region layer created successfully`);
        dispatch(openAllLayersListingScreen());
      }
    } catch (error: any) {
      const errorMsg =
        error?.response?.data?.message ?? error?.message ?? "An error occurred";
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(false); // Indicate that the submission process is complete
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmitForm}
        validateOnChange={false}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          isSubmitting,
          setFieldValue,
          setFieldError,
        }) => (
          <Form>
            <FieldArray name="regions">
              {({ push, remove }) => (
                <div>
                  <div className="pt-3 overflow-y-auto" style={{ height }}>
                    <FilesDragAndDrop push={push} remove={remove} />
                    <div className="">
                      {values.regions.map((_region: any, index: number) => {
                        const fieldsErrors: any = errors.regions
                          ? errors.regions[index]
                          : {
                              name: null,
                              description: null,
                              geoJSON: null,
                            };

                        const fieldsTouched: any = errors.regions
                          ? errors.regions[index]
                          : {
                              name: false,
                              description: false,
                              geoJSON: false,
                            };
                        return (
                          <div key={index} className="pb-1">
                            <div className="relative flex w-full">
                              <div className="flex flex-col items-start justify-start w-[93%] ">
                                <FileUpload
                                  handleOnFileUpload={(files: any) => {
                                    setFieldValue(
                                      `regions[${index}].geoJSON`,
                                      files[0]
                                    );
                                  }}
                                  error={fieldsErrors?.geoJSON}
                                  touched={fieldsTouched?.geoJSON}
                                  values={values.regions[index].geoJSON}
                                />
                                <TextInput
                                  label="Region name*"
                                  type="text"
                                  placeholder="Region name"
                                  name={`regions[${index}].name`}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values?.regions[index]?.name}
                                  touched={fieldsTouched?.name}
                                  error={fieldsErrors?.name}
                                />
                                <TextInput
                                  label="Description*"
                                  type="text"
                                  rows={3}
                                  fieldAs="textarea"
                                  placeholder="Description"
                                  name={`regions[${index}].description`}
                                  handleChange={handleChange}
                                  handleBlur={handleBlur}
                                  value={values.regions[index].description}
                                  error={fieldsErrors?.description}
                                  touched={fieldsTouched?.description}
                                  max={255}
                                />
                                {!fieldsErrors?.description && (
                                  <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                                    255 max characters
                                  </p>
                                )}
                              </div>
                              <button
                                type="button"
                                className="absolute right-0 flex items-center justify-center flex-grow-0 flex-shrink-0 top-4 rounded-3xl"
                                onClick={() => remove(index)}
                              >
                                <TrashIcon />
                              </button>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                    <button
                      disabled={isSubmitting}
                      onClick={() =>
                        push({
                          name: "",
                          description: "",
                          geoJSON: null,
                        })
                      }
                      type="button"
                      className="flex items-center justify-center flex-grow-0 flex-shrink-0 gap-1 rounded-3xl"
                    >
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        className="flex-grow-0 flex-shrink-0 w-6 h-6 "
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12 5C12.5523 5 13 5.44772 13 6V11H18C18.5523 11 19 11.4477 19 12C19 12.5523 18.5523 13 18 13H13V18C13 18.5523 12.5523 19 12 19C11.4477 19 11 18.5523 11 18V13L6 13C5.44772 13 5 12.5523 5 12C5 11.4477 5.44772 11 6 11L11 11V6C11 5.44772 11.4477 5 12 5Z"
                          fill="#005C89"
                        />
                      </svg>
                      <div className="flex justify-center items-center flex-grow-0 flex-shrink-0  pt-1.5 pb-2">
                        <p className="flex-grow-0 flex-shrink-0 text-sm font-semibold text-center text-primary">
                          Add another one
                        </p>
                      </div>
                    </button>
                    <div className="w-full mb-4 mt-4">
                      <div>
                        <p
                          className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                            touched && errors?.color
                              ? "text-accent_1Dark dark:text-accent_1Dark"
                              : ""
                          }`}
                        >
                          Select Fill Color
                        </p>
                        <ColorPicker
                          handlePicker={(color: string) =>
                            setFieldValue("color", color)
                          }
                          color={values.color}
                        />
                      </div>
                      {errors?.color && (
                        <p
                          className={`flex-grow text-xs text-left   ${
                            errors?.color
                              ? "text-accent_1Dark dark:text-accent_1Dark"
                              : "text-textMidLight dark:text-textMain"
                          } `}
                        >
                          {errors.color}
                        </p>
                      )}
                    </div>
                    <div className="w-full mb-4 mt-4">
                      <div>
                        <p
                          className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                            touched && errors?.borderColor
                              ? "text-accent_1Dark dark:text-accent_1Dark"
                              : ""
                          }`}
                        >
                          Select Border Color
                        </p>
                        <ColorPicker
                          handlePicker={(color: string) =>
                            setFieldValue("borderColor", color)
                          }
                          color={values.borderColor}
                        />
                      </div>
                      {errors?.borderColor && (
                        <p
                          className={`flex-grow text-xs text-left   ${
                            errors?.borderColor
                              ? "text-accent_1Dark dark:text-accent_1Dark"
                              : "text-textMidLight dark:text-textMain"
                          } `}
                        >
                          {errors.borderColor}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex justify-between gap-2 pt-2">
                    <Button
                      type="reset"
                      text="Cancel"
                      disabled={isSubmitting}
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2 w-[48.5%]"
                      width="[48.t%]"
                      height="13"
                      fontStyle="font-semibold"
                      variant="outlined"
                      onClick={goBackToAllLayersScreen}
                    />
                    <Button
                      type="submit"
                      text="Save"
                      disabled={isSubmitting || !communities.canCreateGeographyCommunities}
                      filledColor="primary"
                      outlinedColor="primary"
                      textColor="textWhite"
                      className="px-5 py-2 w-[48.5%]"
                      width="[48.5%]"
                      height="13"
                      fontStyle="font-semibold"
                      variant="filled"
                    />
                  </div>
                </div>
              )}
            </FieldArray>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export { UploadForm };

const FileUpload = ({ handleOnFileUpload, error, touched, values }: any) => {
  return (
    <div className="w-full py-2">
      <Dropzone
        onDrop={handleOnFileUpload}
        multiple={false}
        accept={ACCEPTED_FILES_TYPES}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          const fileName = values
            ? values.name
            : "Upload GeoJson, Shape or Zipped Shapefiles, or KML File ";

          return (
            <div className="pb-2">
              <div
                {...getRootProps()}
                className={`border ${
                  error && touched
                    ? "border-accent_1Dark"
                    : "border-lineDark dark:border-lineLight"
                } px-3 py-2 rounded flex items-center justify-between`}
              >
                <input {...getInputProps()} />
                <p className="text-[17px] text-textLightExtra overflow-x-auto whitespace-nowrap mr-2 dark:text-textMain">
                  {fileName}
                </p>
                <CloudUploadIcon />
              </div>
            </div>
          );
        }}
      </Dropzone>
      {touched && error && (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      )}
    </div>
  );
};

const ACCEPTED_FILES_TYPES = {
  "application/geo+json": [".geojson"],
  "application/vnd.google-earth.kml+xml": [".kml"],
  "application/octet-stream": [".shp"],
"application/zip": [".zip"]
};  


const FilesDragAndDrop = ({ push, remove }: any) => {
  const handleOnDropFiles = useCallback(
    (acceptedFiles: any) => {
      acceptedFiles.forEach((file: any) => {
        push({
          name: "",
          description: "",
          geoJSON: file,
        });
      });
    },
    [push]
  );

  return (
    <Dropzone onDrop={handleOnDropFiles} accept={ACCEPTED_FILES_TYPES}>
      {({ getRootProps, getInputProps }) => (
        <>
          <div
            {...getRootProps({ className: "dropzone" })}
            className="flex flex-col items-center self-stretch justify-center flex-grow-0 flex-shrink-0 px-4 py-2 bg-white border border-dashed rounded-lg hover:cursor-pointer border-lineDark"
          >
            <div className="flex flex-col items-center self-stretch justify-start flex-grow-0 flex-shrink-0">
              <input {...getInputProps()} />
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg ">
                <CloudUploadIcon />
              </div>
              <div className="flex items-center self-stretch justify-center flex-grow-0 flex-shrink-0 gap-1 py-1 rounded-lg">
                <div className="flex justify-center items-center flex-grow  px-1 pt-1.5 pb-2">
                  <p className="flex-grow w-[368px] text-sm text-center text-textMid dark:text-textMain">
                    Drag and drop your GeoJSON , Shape Or Kml files here or
                    click in this area
                  </p>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </Dropzone>
  );
};
