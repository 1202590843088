import { Formik } from "formik";
import * as Yup from "yup";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import { openAllLayersListingScreen, resetMap, setSelectedShapeLayerToShapeSelectForm } from "store/geography";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ColorPicker from "Components/Geography/ColorPicker";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";
import { useFetchGlobalAndCommunityVectorLayers } from "Components/Geography/SideBar/hooks";
import { EntityType } from "Components/Geography/SideBar/hooks/useFetchGlobalAndCommunityVectorLayers";
import ReferenceVectorLayersList from "../../ReferenceVectorLayersList";
import usePermissions from "hooks/usePermissions";

const SHAPE_TYPES = [
  { value: "road", label: "Road" },
  { value: "trails", label: "Trails" },
  { value: "river_or_stream", label: "River or Stream" },
  { value: "view", label: "View" },
];

const initialValues = {
  description: "",
  vectorLayers: [],
  name: "",
  shapeType: "",
  mapData: [],
  color: "#FF0000",
  borderColor: "#FF0000",
};
const validationSchema = Yup.object().shape({
  description: Yup.string()
    .max(255, "255 max characters")
    .required("Description is required"),

  vectorLayers: Yup.array()
    .min(1, "At least one layer must be selected")
    .required("Vector layers is a required field"),
  name: Yup.string().required("Name is required"),
  shapeType: Yup.string().required("Shape type is required"),
  mapData: Yup.mixed(),
  color: Yup.string().required("Fill Color is required"),
  borderColor: Yup.string().required("Border Color is required"),
});

interface SelectFormInterface {
  height: string;
}

export const SelectForm = ({ height }: SelectFormInterface) => {
  const [page, setPage] = useState({
    vector: 1,
    publicVector: 1,
  });
  const { communities } = usePermissions();
  const [entityType, setEntityType] = useState<EntityType>(null);
  const [currentOpenToggle, setCurrentOpenToggle] = useState<
    "vector" | "publicVector" | null
  >(null);

  const {
    communityLayersLoading,
    communityVectorLayers,
    globalLayersLoading,
    globalVectorLayers,
  } = useFetchGlobalAndCommunityVectorLayers({
    vectorLayerType: "shape",
    entityType,
    currentOpenToggle,
    setCurrentOpenToggle,
    page,
  });

  const { communityId } = useParams();
  const dispatch = useDispatch();
  const { selectedShapeLayerForShapeSelectForm } = useSelector(
    (state: RootState) => state.geography
  );

  useEffect(() => {
    // reset map
    dispatch(resetMap(true));

    return ()=>{
        dispatch(setSelectedShapeLayerToShapeSelectForm(null))
    }
  }, []);

  // handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    if (!willColorBeVisibleOnMap(values.color)) {
      setFieldError("color", "Please select a fill color that is easily visible");
      return;
    }
    if (!willColorBeVisibleOnMap(values.borderColor)) {
      setFieldError("borderColor", "Please select a border color that is easily visible");
      return;
    }
    if (!selectedShapeLayerForShapeSelectForm) {
      setFieldError("mapData", "Select a shape from map!");
      return;
    }

    const payload = { ...values };
    payload.type = "shape";
    payload.mapData = selectedShapeLayerForShapeSelectForm;

    delete payload.vectorLayers;

    try {
      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, payload);
        Toasts.success(`Shape layer created successfully`);
        // // navigate to all layers screen
        dispatch(openAllLayersListingScreen());
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(true);
    }
  };
  const handleSelectVectorLayer = (
    e: any,
    item: any,
    values: any,
    setFieldValue: any
  ) => {
    const layerId = parseInt(e.target.value);

    const isLayerIdExist = (values.vectorLayers as number[]).includes(layerId);
    const isMapdataExist = values.mapData.some(
      (layer: any) => layer.id === item.id
    );

    setFieldValue(
      "mapData",
      isMapdataExist
        ? values.mapData.filter((layer: any) => layer.id !== item.id)
        : [...values.mapData, item]
    );

    setFieldValue(
      "vectorLayers",
      isLayerIdExist
        ? values.vectorLayers.filter((layer: any) => layer !== layerId)
        : [...values.vectorLayers, layerId]
    );
  };
  const handleScrollEnd = (type: EntityType) => {
    const totalPages =
      type === "vector"
        ? communityVectorLayers.totalPages
        : globalVectorLayers.totalPages;
    if (page && type && totalPages >= page[type] + 1) {
      setPage((prev: any) => ({
        ...prev,
        [type]: prev[type] + 1,
      }));

      if (entityType !== type) {
        setEntityType(type);
      }
    }
  };
  const handleClickOnTogglerBtn = (type: EntityType) => {
    if (currentOpenToggle !== type) setCurrentOpenToggle(type);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="overflow-y-auto " style={{ height }}>
            <p className="text-[15px] text-textMidLight italic mb-6 dark:text-textMain ">
              Turn on the vector layers from which you want to select your
              shape.
            </p>
            <div>
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={globalVectorLayers.vector}
                heading={"Select from Global layers"}
                message="Create vector layers first from the Geography"
                loading={globalLayersLoading}
                type="publicVector"
                newItemType="shape"
                layerRenderingMode="paint"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={currentOpenToggle === "publicVector"}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
              />
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={communityVectorLayers.vector}
                heading={"Select from Community layers"}
                message="Create vector layers first from the Listing section"
                loading={communityLayersLoading}
                type="vector"
                newItemType="shape"
                layerRenderingMode="paint"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={currentOpenToggle === "vector"}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
              />

              {errors.vectorLayers && (
                <p className="flex-grow text-xs text-left capitalize text-accent_1Dark">
                  {errors.vectorLayers}
                </p>
              )}
              {errors.mapData && (
                <p className="flex-grow text-xs text-left capitalize text-accent_1Dark">
                  {errors.mapData}
                </p>
              )}
            </div>
            <div className="flex flex-col items-start justify-start ">
              <TextInput
                label="Shape Name*"
                type="text"
                placeholder="Shape name"
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.name}
                error={errors?.name}
                touched={touched?.name}
              />
            </div>
            <div className="flex flex-col items-start justify-start w-full mb-2">
              <CustomSelect
                data={SHAPE_TYPES}
                placeholder="Select road, trails, river or stream , view"
                label="Shape type*"
                name="shapeType"
                handleOnChange={(selectedOpt: any) =>
                  setFieldValue(selectedOpt.name, selectedOpt.value)
                }
                touched={touched?.shapeType}
                error={errors?.shapeType}
              />
            </div>
            <div className="w-full mb-4">
              <TextInput
                label="Description*"
                type="text"
                rows={3}
                fieldAs="textarea"
                placeholder="Description"
                name="description"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
                error={errors?.description}
                touched={touched?.description}
              />

              {!errors?.description && (
                <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                  255 max characters
                </p>
              )}
            </div>
            <div className="w-full mb-4">
              <div>
                <p
                  className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                    touched && errors?.color
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : ""
                  }`}
                >
                  Select Fill Color
                </p>
                <ColorPicker
                  handlePicker={(color: string) =>
                    setFieldValue("color", color)
                  }
                  color={values.color}
                />
              </div>
              {errors?.color && (
                <p
                  className={`flex-grow text-xs text-left   ${
                    errors?.color
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : "text-textMidLight dark:text-textMain"
                  } `}
                >
                  {errors.color}
                </p>
              )}
            </div>
            <div className="w-full mb-4">
              <div>
                <p
                  className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                    touched && errors?.borderColor
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : ""
                  }`}
                >
                  Select Border Color
                </p>
                <ColorPicker
                  handlePicker={(color: string) =>
                    setFieldValue("borderColor", color)
                  }
                  color={values.borderColor}
                />
              </div>
              {errors?.borderColor && (
                <p
                  className={`flex-grow text-xs text-left   ${
                    errors?.borderColor
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : "text-textMidLight dark:text-textMain"
                  } `}
                >
                  {errors.borderColor}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between gap-2">
            <Button
              disabled={isSubmitting}
              type="reset"
              text="Cancel"
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2 w-[48.5%]"
              width="[48.t%]"
              height="13"
              fontStyle="font-semibold"
              variant="outlined"
              onClick={() => dispatch(openAllLayersListingScreen())}
            />
            <Button
              type="submit"
              text="Save"
              disabled={isSubmitting || !communities.canCreateGeographyCommunities}
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2 w-[48.5%]"
              width="[48.5%]"
              height="13"
              fontStyle="font-semibold"
              variant="filled"
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

const CustomSelect = ({
  data,
  placeholder,
  label,
  handleOnChange,
  name,
  error,
  touched,
}: any) => {
  const [selectedOpt, setSelectedOpt] = useState<any>(null);
  const [doShowMenu, setDoShowMenu] = useState(false);

  const handleClickOnOption = (opt: any) => {
    setSelectedOpt(opt);
    handleOnChange({ value: opt.value, name });
    toggleMenu();
  };
  const toggleMenu = () => {
    setDoShowMenu(!doShowMenu);
  };
  return (
    <div className="flex flex-col w-full">
      <label className="w-full pb-1 text-sm text-left capitalize text-secondaryMidLight dark:text-caption">
        {label}
      </label>
      <div className="relative">
        <button
          type="button"
          className={`border ${
            error && touched
              ? "border-accent_1Dark"
              : "border-lineDark dark:border-lineLight"
          } px-3 py-2 rounded flex justify-between items-center cursor-pointer w-full`}
          onClick={toggleMenu}
        >
          <span
            className={`${
              selectedOpt
                ? "text-secondary dark:text-textMain"
                : "text-textLightExtra dark:text-caption"
            } text-base`}
          >
            {selectedOpt && selectedOpt.label ? selectedOpt.label : placeholder}
          </span>
          <CheveronDownIcon className="w-6 text-secondary" />
        </button>
        {touched && error && (
          <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark pt-2">
            {error}
          </p>
        )}
        {doShowMenu && (
          <div className="absolute z-50 flex flex-col items-start w-full py-2 border rounded font-Overpass border-lineDark dark:border-lineLight bg-bgWhite dark:bg-secondaryLight ">
            {data.map((opt: any, index: number) => {
              return (
                <button
                  key={index.toString()}
                  onClick={() => handleClickOnOption(opt)}
                  className={`w-full px-4 py-1 text-left text-secondaryMid ${
                    selectedOpt && selectedOpt.value === opt.value
                      ? "dark:bg-primary_40 dark:text-textMain"
                      : ""
                  } dark:hover:bg-primary_40 dark:text-caption`}
                  type="button"
                >
                  {opt.label}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
