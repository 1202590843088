import proj4 from "proj4";
//@ts-ignore
import epsg from "epsg";
import { v4 as uuidv4 } from 'uuid';

import generateUniqueId from "generate-unique-id";

interface GeoJSONPoint {
  type: "Point";
  coordinates: [number, number];
}

interface GeoJSONMultiPoint {
  type: "MultiPoint";
  coordinates: [number, number][];
}

interface GeoJSONLineString {
  type: "LineString";
  coordinates: [number, number][];
}

interface GeoJSONMultiLineString {
  type: "MultiLineString";
  coordinates: [number, number][][];
}

interface GeoJSONPolygon {
  type: "Polygon";
  coordinates: [number, number][][];
}

interface GeoJSONMultiPolygon {
  type: "MultiPolygon";
  coordinates: [number, number][][][];
}

interface GeoJSONGeometryCollection {
  type: "GeometryCollection";
  geometries: GeoJSONGeometry[];
}

type GeoJSONGeometry =
  | GeoJSONPoint
  | GeoJSONMultiPoint
  | GeoJSONLineString
  | GeoJSONMultiLineString
  | GeoJSONPolygon
  | GeoJSONMultiPolygon
  | GeoJSONGeometryCollection;

interface Feature {
  type: "Feature";
  geometry: GeoJSONGeometry;
  properties: any;
  crs?: {
    properties: {
      name: string;
    };
    type: string;
  };
}

export interface IFeatureCollection {
  type: "FeatureCollection";
  crs: {
    properties: {
      name: string;
    };
    type: string;
  };
  features: Feature[];
}

let targetProjection = epsg["EPSG:4326"];

export function convertGeoJSON(
  input: IFeatureCollection | Feature,
  srcProjection: string | null = null
): any {
  let sourceProjection: string;

  if (srcProjection !== null) {
    sourceProjection = srcProjection;
  } else if (
    input?.crs &&
    input?.crs?.properties &&
    input?.crs?.properties?.name
  ) {
    sourceProjection = epsg[input?.crs?.properties?.name];
  } else {
    sourceProjection = epsg["EPSG:4326"];
  }

  if (input === null) return;
  if (input.type === "FeatureCollection") {
    input?.features.forEach((feature) =>
      convertFeature(feature, sourceProjection)
    );
    return input;
  } else if (input.type === "Feature") {
    return convertFeature(input, sourceProjection);
  } else {
    // throw new Error("Unsupported GeoJSON type");
    return input;
  }
}

function convertFeature(feature: any, sourceProjection: string): Feature {
  feature.geometry = convertGeometry(feature.geometry, sourceProjection);
  feature.id = generateUniqueId({
    length: 15,
    useLetters: false
  });

  return feature;
}

function convertGeometry(
  geometry: GeoJSONGeometry,
  sourceProjection: string
): GeoJSONGeometry {
  switch (geometry?.type) {
    case "Point":
      geometry.coordinates = convertCoordinates(
        geometry.coordinates,
        sourceProjection
      );
      break;
    case "MultiPoint":
      geometry.coordinates = geometry.coordinates.map((item: any) => {
        return convertCoordinates(item, sourceProjection);
      });
      break;
    case "LineString":
      geometry.coordinates = geometry.coordinates.map((item: any) => {
        return convertCoordinates(item, sourceProjection);
      });
      break;
    case "MultiLineString":
      geometry.coordinates = geometry.coordinates.map((ring: any[]) =>
        ring.map((item: any) => {
          return convertCoordinates(item, sourceProjection);
        })
      );
      break;
    case "Polygon":
      geometry.coordinates = geometry.coordinates.map((ring: any[]) =>
        ring.map((item: any) => {
          return convertCoordinates(item, sourceProjection);
        })
      );
      break;
    case "MultiPolygon":
      geometry.coordinates = geometry.coordinates.map((polygon: any[][]) =>
        polygon.map((ring: any[]) =>
          ring.map((item: any) => {
            return convertCoordinates(item, sourceProjection);
          })
        )
      );
      break;

    case "GeometryCollection":
      if (geometry?.geometries) {
        geometry.geometries = geometry.geometries.map((geom) =>
          convertGeometry(geom, sourceProjection)
        );
      }
      break;

    // throw new Error("Unsupported GeoJSON type");
  }
  return geometry;
}

function convertCoordinates(
  coords: [number, number],
  sourceProjection: string
): [number, number] {
  if (Math.abs(coords[0]) > 180 || Math.abs(coords[1]) > 90) {
    return proj4(sourceProjection, targetProjection, coords);
  }
  return coords;
}
