import { Dispatch, useEffect, useState } from "react";
// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
// APIs services
import apiLibrary from "services/api";

// Store utils
import { useDispatch } from "react-redux";
import { openAllLayersListingScreen } from "store/geography";
// Icons
import {
  ArrowLeftIcon,
  CheveronDownIcon,
  UploadIcon,
} from "assets/icons/HeroIcons";
// Third party utils
import { Formik } from "formik";
import Dropzone from "react-dropzone";
import * as Yup from "yup";
import { useParams } from "react-router-dom";
import handleShapeAndKMLTypeFiles from "../../utils/handleShapeAndKMLTypeFiles";
import Head from "view/components/Head";
import ColorPicker from "Components/Geography/ColorPicker";
import separateColorAndOpacity from "utils/separateColorAndOpacity";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";
import shp from "shpjs";
import JSZip from "jszip";
import usePermissions from "hooks/usePermissions";
// Types
interface I_CreateVectorLayer {
  setActiveScreen: Dispatch<string>;
}
// Global constants
const MAXIMUM_FILE_SIZE = 200; // kbs
const SHAPE_TYPES = [
  { value: "polygon", label: "Polygon" },
  { value: "linestring", label: "Line" },
  { value: "point", label: "Point" },
];

// Schema
const vectorLayerSchema = Yup.object().shape({
  name: Yup.string()
    .max(255, "255 max characters")
    .required("Layer name is required"),
  description: Yup.string()
    .required("Description is required")
    .max(255, "255 max characters"),
  legendFileId: Yup.string(),
  geoFileId: Yup.string().required("GeoJson Is Required"),
  type: Yup.string().required("Type Is Required"),
  color: Yup.string().required("Fill color is Required"),
  borderColor: Yup.string().required("Border color is Required"),
});

const CreateVectorLayer = () => {
  const dispatch = useDispatch();
  const { communityId } = useParams();

  const initialValues = {
    name: "",
    description: "",
    legendFileId: "",
    geoFileId: "",
    type: "vector",
    shapeType: "polygon",
    color: "#FF0000",
    borderColor: "#FF0000",
  };

  // handlers
  const goBackToAllLayersScreen = () => {
    dispatch(openAllLayersListingScreen());
  };
  const { communities } = usePermissions();
  const isGlobalRasterLayer = !!(!communityId || global);
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    try {
      if (!willColorBeVisibleOnMap(values.color)) {
        setFieldError("color", "Please select a fill color that is easily visible");
        return;
      }
      if (!willColorBeVisibleOnMap(values.borderColor)) {
        setFieldError("borderColor", "Please select a border color that is easily visible");
        return;
      }

      const uploadFile = async (fileId: any, isGeoJson = false) => {
        const response = await apiLibrary.file.fileUpload(
          fileId,
          isGeoJson,
          "public",
          "vector",
          values.shapeType
        );
        return response.data.id;
      };

      const uploadIfNeeded = async (fileId: any, isGeoJson: any) => {
        if (fileId && fileId !== "") {
          return uploadFile(fileId, isGeoJson);
        }
        return null;
      };

      const mapData = await handleShapeAndKMLTypeFiles(values.geoFileId);

      const blob = new Blob([JSON.stringify(mapData)], {
        type: "application/json",
      });
      const file = new File([blob], "location.geojson", {
        type: "application/geo+json",
      });

      const results = await Promise.allSettled([
        uploadIfNeeded(file, true),
        uploadIfNeeded(values.legendFileId, false),
      ]);

      const geoFileIdResult = results[0];
      const legendFileIdResult = results[1];

      if (
        geoFileIdResult.status === "rejected" ||
        legendFileIdResult.status === "rejected"
      ) {
        // You can customize the error message to be more informative
        let errorMessage = "";
        if (geoFileIdResult.status === "rejected") {
          errorMessage += `Geojson Upload Error ; ${geoFileIdResult.reason?.response?.data?.message ??
            geoFileIdResult.reason.message
            }`;
        }
        if (legendFileIdResult.status === "rejected") {
          //@ts-ignore
          errorMessage += `Legend Upload Error ; ${legendFileIdResult.reason?.response?.data?.message ??
            legendFileIdResult.reason.message
            }`;
        }
        throw new Error(errorMessage);
      }

      const geoFileId = geoFileIdResult.value;
      const legendFileId = legendFileIdResult.value || values.legendFileId;

      const payload = {
        ...values,
        color: values.shapeType === "point" ? "red" : values.color,
        borderColor: values.shapeType === "point" ? "red" : values.borderColor,
        geoFileId,
        legendFileId,
      };

      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, payload);
      } else {
        await apiLibrary.geography.createLayerForAdmin(payload);
      }

      Toasts.success("Vector layer created successfully");
      dispatch(openAllLayersListingScreen());
    } catch (error: any) {
      if (error.code === "CRS_MISSING" || error.code === "SHP_OR_PRJ_MISSING") {
        setFieldError("geoFileId", error.details);
      } else {
        const errorMsg = error?.response?.data?.message ?? error.message;
        Toasts.error(errorMsg);
      }
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="h-full">
      <Head title="Geography: Add Vector Layer" />
      <Breadcrumb
        label="Add Vector Layer"
        handleClickOnBreadCrumb={goBackToAllLayersScreen}
      />
      <Formik
        initialValues={initialValues}
        validationSchema={vectorLayerSchema}
        onSubmit={handleSubmitForm}
      // onSubmit={testhandler}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setFieldError,
        }) => (
          <form
            onSubmit={handleSubmit}
          // className="h-[90%] overflow-y-auto overflow-x-hidden will-change-scroll"
          >
            <div className="max-h-[70vh] overflow-y-auto mb-4 px-2">
              <div className="flex flex-col items-start justify-start ">
                <TextInput
                  label="Layer Name*"
                  type="text"
                  placeholder="Layer Name"
                  name="name"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.name}
                  error={errors?.name}
                  touched={touched?.name}
                />
              </div>
              <div className="w-full mb-2">
                <TextInput
                  label="Description*"
                  type="text"
                  rows={3}
                  fieldAs="textarea"
                  placeholder="Description"
                  name="description"
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  value={values.description}
                  error={errors?.description}
                  touched={touched?.description}
                />
                {!errors?.description && (
                  <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                    255 max characters
                  </p>
                )}
              </div>
              <div className="flex flex-col items-start justify-start mb-2">
                <FileUpload
                  handleOnFileUpload={async (files: any) => {
                    const file = files?.[0];
                    setFieldValue("geoFileId", file);
                  }}
                  error={errors?.geoFileId}
                  touched={touched?.geoFileId}
                  title={"Select GeoJson, Shape or Zipped Shapefiles, or KML File*"}
                  ACCEPTED_FILES_TYPES={{
                    "application/geo+json": [".geojson"],
                    "application/vnd.google-earth.kml+xml": [".kml"],
                    "application/octet-stream": [".shp"],
                    "application/zip": [".zip"]
                  }}
                />
              </div>
              <div className="flex flex-col items-start justify-start w-full mb-2">
                <CustomSelect
                  data={SHAPE_TYPES}
                  placeholder="Select Polygon, Line Or Point"
                  label="Type"
                  name="shapeType"
                  handleOnChange={(selectedOpt: any) =>
                    setFieldValue(selectedOpt.name, selectedOpt.value)
                  }
                  value={values.shapeType}
                  touched={touched?.shapeType}
                  error={errors?.shapeType}
                />
              </div>
              <div className="flex flex-col items-start justify-start mb-2">
                <FileUpload
                  ACCEPTED_FILES_TYPES={{
                    "image/jpeg": [".jpeg", ".jpg", ".png"],
                  }}
                  handleOnFileUpload={async (files: any) => {
                    const file = files?.[0];
                    const fileSize = file.size / 1024; // convert bytes to kb
                    if (fileSize > MAXIMUM_FILE_SIZE) {
                      return setFieldError(
                        "legendFileId",
                        "File size must be less than or equal to 200kb!"
                      );
                    } else {
                      setFieldValue("legendFileId", file);
                    }
                  }}
                  error={errors?.legendFileId}
                  touched={touched?.legendFileId}
                  title="Legend"
                />
              </div>
              {values.shapeType !== "point" && (
                <>
                  <div className="w-full mt-4 mb-4">
                    <div>
                      <p
                        className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${errors?.color
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : ""
                          }`}
                      >
                        Select Fill Color
                      </p>
                      <ColorPicker
                        handlePicker={(color: string) =>
                          setFieldValue("color", color)
                        }
                        color={values.color}
                      />
                    </div>
                    {errors?.color && (
                      <p
                        className={`flex-grow text-xs text-left   ${errors?.color
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : "text-textMidLight dark:text-textMain"
                          } `}
                      >
                        {errors.color}
                      </p>
                    )}
                  </div>
                  {values.shapeType !== "linestring" && <div className="w-full mt-4 mb-4">
                    <div>
                      <p
                        className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${errors?.borderColor
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : ""
                          }`}
                      >
                        Select Border Color
                      </p>
                      <ColorPicker
                        handlePicker={(color: string) =>
                          setFieldValue("borderColor", color)
                        }
                        color={values.borderColor}
                      />
                    </div>
                    {errors?.borderColor && (
                      <p
                        className={`flex-grow text-xs text-left   ${errors?.borderColor
                          ? "text-accent_1Dark dark:text-accent_1Dark"
                          : "text-textMidLight dark:text-textMain"
                          } `}
                      >
                        {errors.borderColor}
                      </p>
                    )}
                  </div>}
                </>
              )}
            </div>

            <div className="flex justify-between gap-2">
              <Button
                disabled={isSubmitting}
                type="reset"
                text="Cancel"
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.5%]"
                height="13"
                fontStyle="font-semibold"
                variant="outlined"
                onClick={goBackToAllLayersScreen}
              />
              <Button
                type="submit"
                text="Save"
                disabled={isSubmitting || (!isGlobalRasterLayer && !communities.canCreateGeographyCommunities)}
                filledColor="primary"
                outlinedColor="primary"
                textColor="textWhite"
                className="px-5 py-2 w-[48.5%]"
                width="[48.5%]"
                height="13"
                fontStyle="font-semibold"
                variant="filled"
              />
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export { CreateVectorLayer };

const Breadcrumb = ({ label, handleClickOnBreadCrumb }: any) => {
  return (
    <button
      className="flex items-center gap-4 mb-6"
      onClick={handleClickOnBreadCrumb}
    >
      <ArrowLeftIcon style={{ width: "24px" }} />
      <span className="text-[15px] text-secondaryMid dark:text-textMain">
        {label}
      </span>
    </button>
  );
};
const FileUpload = ({
  handleOnFileUpload,
  error,
  touched,
  title,
  ACCEPTED_FILES_TYPES,
}: any) => {
  return (
    <div className="w-full py-2">
      <label className="pb-1 text-sm text-textMid dark:text-caption">
        {title}
      </label>
      <Dropzone
        onDrop={handleOnFileUpload}
        multiple={false}
        accept={ACCEPTED_FILES_TYPES}
      >
        {({ getRootProps, getInputProps, acceptedFiles }) => {
          const fileName =
            acceptedFiles && acceptedFiles.length > 0
              ? acceptedFiles[0]?.name
              : `Upload File `;

          return (
            <div className="pb-2">
              <div
                {...getRootProps()}
                className={`border ${error && touched
                  ? "border-accent_1Dark"
                  : "border-lineDark dark:border-lineLight"
                  } px-3 py-2 rounded flex items-center justify-between`}
              >
                <input {...getInputProps()} />
                <p
                  className={`text-[17px] text-textLightExtra overflow-x-auto whitespace-nowrap mr-2 ${acceptedFiles[0]?.name
                    ? "dark:text-textMain"
                    : "dark:text-caption"
                    }`}
                >
                  {fileName}
                </p>
                <UploadIcon />
              </div>
            </div>
          );
        }}
      </Dropzone>
      {error ? (
        <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark">
          {error}
        </p>
      ) : (
        title.toLowerCase() === "legend" && (
          <p className="flex-grow pt-1 text-xs text-left text-textMidLight dark:text-caption">
            Use an image that’s at least 200 x 200 pixels and 200KB or less.
          </p>
        )
      )}
    </div>
  );
};

const CustomSelect = ({
  data,
  placeholder,
  label,
  handleOnChange,
  name,
  error,
  touched,
  value,
}: any) => {
  const [selectedOpt, setSelectedOpt] = useState<any>(null);
  const [doShowMenu, setDoShowMenu] = useState(false);

  const handleClickOnOption = (opt: any) => {
    setSelectedOpt(opt);
    handleOnChange({ value: opt.value, name });
    toggleMenu();
  };
  const toggleMenu = () => {
    setDoShowMenu(!doShowMenu);
  };

  useEffect(() => {
    if (value) {
      setSelectedOpt(value);
    }
  }, []);

  return (
    <div className="flex flex-col w-full">
      <label className="w-full pb-1 text-sm text-left capitalize text-secondaryMidLight dark:text-caption">
        {label}
      </label>
      <div className="relative">
        <button
          type="button"
          className={`border ${error && touched
            ? "border-accent_1Dark"
            : "border-lineDark dark:border-lineLight"
            } px-3 py-2 rounded flex justify-between items-center cursor-pointer w-full`}
          onClick={toggleMenu}
        >
          <span
            className={`${selectedOpt
              ? "text-secondary dark:text-textMain"
              : "text-textLightExtra dark:text-caption"
              } text-base`}
          >
            {selectedOpt && selectedOpt.label ? selectedOpt.label : placeholder}
          </span>
          <CheveronDownIcon className="w-6 text-secondary" />
        </button>
        {touched && error && (
          <p className="flex-grow w-[1/2] text-xs text-left text-accent_1Dark pt-2">
            {error}
          </p>
        )}
        {doShowMenu && (
          <div className="absolute z-50 flex flex-col items-start w-full py-2 border rounded font-Overpass border-lineDark dark:border-lineLight bg-bgWhite dark:bg-secondaryLight ">
            {data.map((opt: any, index: number) => {
              return (
                <button
                  key={index.toString()}
                  onClick={() => handleClickOnOption(opt)}
                  className={`w-full px-4 py-1 text-left text-secondaryMid ${selectedOpt && selectedOpt.value === opt.value
                    ? "dark:bg-primary_40 dark:text-textMain"
                    : ""
                    } dark:hover:bg-primary_40 dark:text-caption`}
                  type="button"
                >
                  {opt.label}
                </button>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
