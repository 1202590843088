import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import FileBackupIcon from "assets/icons/HeroIcons/FileBackupIcon";
import Button from "view/components/Button";
import apiLibrary from "services/api";
import { useSelector } from "react-redux";
import { RootState } from "store";
import LoadingBar from "react-top-loading-bar";
import { TailSpin } from "react-loader-spinner";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import { AnyAction } from "redux";
import { reportVersionsAction } from "store/runReport";
import { runReportData } from "store/runReport/initialState";

export const LoaderReportCard = () => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const [progress, setProgress] = useState(0);

  const navigate = useNavigate();
  const { reportsId } = useParams<any>();

  const startLoaderForVersions = async () => {
    let progress = 10;

    // Define the number of steps and maximum progress value
    const steps = 3;
    const maxProgress = 100;

    for (let step = 1; step <= steps; step++) {
      // Calculate the random progress increment for this step
      const increment = (maxProgress - progress) / (steps - step + 1);

      // Add the increment to the progress
      progress += increment;
      setProgress(Math.min(maxProgress, progress));

      // Delay for 1-2 seconds (adjust the delay as needed)
      await new Promise((resolve) =>
        setTimeout(resolve, Math.random() * 1000 + 1000)
      );
    }
    const fetchReportData = async () => {
      try {
        const { data } =
          await apiLibrary.Reports.getProcessedReportVersion(reportsId);
        if (data?.id) {
          setProgress(100); // Set progress to 100 once data is successfully fetched
          dispatch(reportVersionsAction(false));
          navigate(`/reports/${reportsId}/profile`);
        }
      } catch (error: any) {
        console.error("Error fetching report data", error);
      }
    };
    await fetchReportData();
  };
  useEffect(() => {
    const interval = setInterval(async() => {
    await  startLoaderForVersions(); // Retry the API call
    }, 5000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full p-6 bg-bgWhite">
      <div className="flex flex-col items-center justify-center w-full">
        <FileBackupIcon />
        <p className="pt-6 text-lg font-semibold text-center">
          Please wait! Your report is being processed...
        </p>
        <p className="text-sm text-center textMidLight">
          Processing... Approximate remaining time about 1 min...
        </p>
      </div>
      {/* {progress > 0 && ( */}
      <div className="my-4 spinner">
        <TailSpin
          height="40"
          width="40"
          color="#005C89"
          ariaLabel="tail-spin-loading"
          radius="3"
          wrapperStyle={{}}
          wrapperClass="tailspin-loader"
          visible={true}
          strokeWidth={3}
        />
      </div>
      <div className="flex items-center gap-2 loadingbar">
        <div className="relative w-[30vw] h-1 bg-secondary_25">
          <LoadingBar
            color="#005C89"
            progress={progress}
            height={3}
            containerClassName="!absolute"
            shadow={false}
            waitingTime={1000000}
          />
        </div>
        <p className="text-sm font-Overpass text-textMid dark:text-caption ">
          {progress}%
        </p>
      </div>
      <div className="flex justify-center mt-6 mb-4">
        <Button
          type="button"
          text="Go to Reports Directory"
          filledColor="primary"
          outlinedColor="primary"
          textColor="textWhite"
          className="px-5 py-2 "
          width="35"
          height="13"
          fontStyle="font-semibold"
          variant="outlined"
          onClick={() => navigate(`/reports/list`)}
        />
      </div>
      <div>
        <p className="text-primary text-lg">
          Your report will keep processing in the background, you will recieve a
          notification when the report is done
        </p>
      </div>
    </div>
  );
};
export default LoaderReportCard;
