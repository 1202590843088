import { Formik } from "formik";
import * as Yup from "yup";
import CheveronUpIcon from "assets/icons/HeroIcons/CheveronUpIcon";
import { CheveronDownIcon } from "assets/icons/HeroIcons";
import CustomCheckbox from "view/components/CheckBox";
import splitString from "utils/splitString";
// Components
import Button from "view/components/Button";
import TextInput from "view/pages/MyProfile/Components/Inputs/TextInput";
import { Toasts } from "view/components/Toasts";
import { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import apiLibrary from "services/api";
import {
  openAllLayersListingScreen,
  resetMap,
  setSelectedRegionLayerToRegionSelectForm,
  updateSelectedLayersItems,
} from "store/geography";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { RootState } from "store";
import ColorPicker from "Components/Geography/ColorPicker";
import willColorBeVisibleOnMap from "utils/willColorBeVisibleOnMap";
import InfiniteScroll from "Components/Geography/InfiniteScroll";
import { TailSpin } from "react-loader-spinner";
import { useFetchGlobalAndCommunityVectorLayers } from "Components/Geography/SideBar/hooks";
import { EntityType } from "Components/Geography/SideBar/hooks/useFetchGlobalAndCommunityVectorLayers";
import ReferenceVectorLayersList from "../../ReferenceVectorLayersList";
import usePermissions from "hooks/usePermissions";

const initialValues = {
  description: "",
  vectorLayers: [],
  name: "",
  mapData: [],
  color: "#FF0000",
  borderColor: "#FF0000",
};
const validationSchema = Yup.object().shape({
  description: Yup.string()
    .max(255, "255 max characters")
    .required("Description is required"),
  vectorLayers: Yup.array()
    .min(1, "At least one layer must be selected")
    .required("Vector layers is a required field"),
  name: Yup.string().required("Name is required"),
  mapData: Yup.mixed(),
  color: Yup.string().required("Fill Color is required"),
  borderColor: Yup.string().required("Border Color is required"),
});

interface SelectFormInterface {
  height: string;
}

export const SelectForm = ({ height }: SelectFormInterface) => {
  const [page, setPage] = useState({
    vector: 1,
    publicVector: 1,
  });
  const [entityType, setEntityType] = useState<EntityType>(null);
  const [currentOpenToggle, setCurrentOpenToggle] = useState<
    "vector" | "publicVector" | null
  >(null);

  const {
    communityLayersLoading,
    communityVectorLayers,
    globalLayersLoading,
    globalVectorLayers,
  } = useFetchGlobalAndCommunityVectorLayers({
    vectorLayerType: "polygon",
    entityType,
    currentOpenToggle,
    setCurrentOpenToggle,
    page,
  });

  const { selectedRegionLayerForRegionSelectForm } = useSelector(
    (state: RootState) => state.geography
  );
    const {communities} = usePermissions();
  const { communityId } = useParams();
  const dispatch = useDispatch();

  useEffect(()=>{
    // reset selected layers
    dispatch(resetMap(true));

    return ()=>{
        dispatch(setSelectedRegionLayerToRegionSelectForm(null))
    }
  },[])

  // handlers
  const handleSubmitForm = async (
    values: any,
    { setSubmitting, setFieldError }: any
  ) => {
    if (!selectedRegionLayerForRegionSelectForm) {
      setFieldError("mapData", "Select a shape from map!");
      return;
    }
    if (!willColorBeVisibleOnMap(values.color)) {
      setFieldError("color", "Please select a fill color that is easily visible");
      return;
    }
    if (!willColorBeVisibleOnMap(values.borderColor)) {
      setFieldError("borderColor", "Please select a border color that is easily visible");
      return;
    }

    const payload = { ...values };
    payload.type = "region";
    // payload.mapData = getAllFeatures(values.mapData);
    payload.mapData = selectedRegionLayerForRegionSelectForm;

    delete payload.vectorLayers;

    try {
      if (communityId) {
        await apiLibrary.geography.createLayer(communityId, payload);
        Toasts.success(`Region layer created successfully`);

        dispatch(openAllLayersListingScreen());
      }
    } catch (error: any) {
      const errorMsg = error?.response?.data?.message ?? error?.message;
      Toasts.error(errorMsg);
    } finally {
      setSubmitting(true);
    }
  };
  const handleSelectVectorLayer = (
    e: any,
    item: any,
    values: any,
    setFieldValue: any
  ) => {
    const layerId = parseInt(e.target.value);

    const isLayerIdExist = (values.vectorLayers as number[]).includes(layerId);
    const isMapdataExist = values.mapData.some(
      (layer: any) => layer.id === item.id
    );

    setFieldValue(
      "mapData",
      isMapdataExist
        ? values.mapData.filter((layer: any) => layer.id !== item.id)
        : [...values.mapData, item]
    );

    setFieldValue(
      "vectorLayers",
      isLayerIdExist
        ? values.vectorLayers.filter((layer: any) => layer !== layerId)
        : [...values.vectorLayers, layerId]
    );
  };
  const handleScrollEnd = (type: EntityType) => {
    const totalPages =
      type === "vector"
        ? communityVectorLayers.totalPages
        : globalVectorLayers.totalPages;
    if (page && type && totalPages >= page[type] + 1) {
      setPage((prev: any) => ({
        ...prev,
        [type]: prev[type] + 1,
      }));

      if (entityType !== type) {
        setEntityType(type);
      }
    }
  };
  const handleClickOnTogglerBtn = (type: EntityType) => {
    if (currentOpenToggle !== type) setCurrentOpenToggle(type);
  };

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        setFieldError,
      }) => (
        <form onSubmit={handleSubmit}>
          <div className="overflow-y-auto" style={{ height }}>
            <p className="text-[15px] text-textMidLight italic mb-6 dark:text-textMain ">
              Turn on the vector layers from which you want to select your
              region.
            </p>

            <div>
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={globalVectorLayers.vector}
                heading={"Select from Global layers"}
                message="Create vector layers first from the Geography"
                loading={globalLayersLoading}
                type="publicVector"
                newItemType="region"
                layerRenderingMode="paint"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={currentOpenToggle === "publicVector"}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
              />
              <ReferenceVectorLayersList
                selectLayersHandler={(e: any, item: any) =>
                  handleSelectVectorLayer(e, item, values, setFieldValue)
                }
                layerIds={values.vectorLayers}
                layers={communityVectorLayers.vector}
                heading={"Select from Community layers"}
                message="Create vector layers first from the Listing section"
                loading={communityLayersLoading}
                type="vector"
                newItemType="region"
                layerRenderingMode="paint"
                handleScrollEnd={handleScrollEnd}
                doShowDataList={currentOpenToggle === "vector"}
                handleClickOnTogglerBtn={handleClickOnTogglerBtn}
              />

              {errors.vectorLayers && (
                <p className="flex-grow text-xs text-left capitalize text-accent_1Dark">
                  {errors.vectorLayers}
                </p>
              )}
              {errors.mapData && (
                <p className="flex-grow text-xs text-left capitalize text-accent_1Dark">
                  {errors.mapData}
                </p>
              )}
            </div>
            <div className="flex flex-col items-start justify-start ">
              <TextInput
                label="Region Name*"
                type="text"
                placeholder="Region name"
                name="name"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.name}
                error={errors?.name}
                touched={touched?.name}
              />
            </div>
            <div className="w-full mb-4">
              <TextInput
                label="Description*"
                type="text"
                rows={3}
                fieldAs="textarea"
                placeholder="Description"
                name="description"
                handleChange={handleChange}
                handleBlur={handleBlur}
                value={values.description}
                error={errors?.description}
                touched={touched?.description}
              />

              {!errors?.description && (
                <p className="flex-grow text-xs text-left text-textMidLight dark:text-textMain">
                  255 max characters
                </p>
              )}
            </div>

            <div className="w-full mb-4">
              <div>
                <p
                  className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                    touched && errors?.color
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : ""
                  }`}
                >
                  Select Fill Color
                </p>
                <ColorPicker
                  handlePicker={(color: string) =>
                    setFieldValue("color", color)
                  }
                  color={values.color}
                />
              </div>
              {errors?.color && (
                <p
                  className={`flex-grow text-xs text-left   ${
                    errors?.color
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : "text-textMidLight dark:text-textMain"
                  } `}
                >
                  {errors.color}
                </p>
              )}
            </div>
            <div className="w-full mb-4">
              <div>
                <p
                  className={`flex-grow pb-1 w-full text-sm font-medium text-left capitalize text-secondaryMid dark:text-caption ${
                    touched && errors?.borderColor
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : ""
                  }`}
                >
                  Select Border Color
                </p>
                <ColorPicker
                  handlePicker={(color: string) =>
                    setFieldValue("borderColor", color)
                  }
                  color={values.borderColor}
                />
              </div>
              {errors?.borderColor && (
                <p
                  className={`flex-grow text-xs text-left   ${
                    errors?.borderColor
                      ? "text-accent_1Dark dark:text-accent_1Dark"
                      : "text-textMidLight dark:text-textMain"
                  } `}
                >
                  {errors.borderColor}
                </p>
              )}
            </div>
          </div>

          <div className="flex justify-between pt-2 gap-2">
            <Button
              disabled={isSubmitting}
              type="reset"
              text="Cancel"
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2 w-[48.5%]"
              width="[48.t%]"
              height="13"
              fontStyle="font-semibold"
              variant="outlined"
              onClick={() => dispatch(openAllLayersListingScreen())}
            />
            <Button
              type="submit"
              text="Save"
              disabled={isSubmitting || !communities.canCreateGeographyCommunities}
              filledColor="primary"
              outlinedColor="primary"
              textColor="textWhite"
              className="px-5 py-2 w-[48.5%]"
              width="[48.5%]"
              height="13"
              fontStyle="font-semibold"
              variant="filled"
            />
          </div>
        </form>
      )}
    </Formik>
  );
};

// interface GeoData {
//   geoFilePath: string;
//   id: number;
//   legendFilePath: string | null;
//   mapData: {
//     features: any[];
//     type: string;
//   };
//   name: string;
// }

// function getAllFeatures(data: GeoData[]): any {
//   const allFeatures: any[] = [];

//   data.forEach((item) => {
//     if (item.mapData && item.mapData.features) {
//       allFeatures.push(...item.mapData.features);
//     }
//   });

//   return {
//     features: allFeatures,
//     type: "FeatureCollection",
//   };
// }
