import generateUniqueId from "generate-unique-id";
import parseId from "../utils/parseId";
import splitCamelCaseToString from "../utils/splitCamelCaseToString";
import { v4 as uuidv4 } from "uuid";
import { uniqueIdGenertorInNumber } from "../utils/structureFormJSON";

function convertJsonOfPointPicker(question: any, data: any) {
  const {
    allCommunityMarkers,
    selectedPointsIdToHighlight,
    mapCenterPoint,
    selectedDefaultLayers,
    id,
    ableToCheckMultiple,
    name,
    title,
    description,
    isRequired,
    zoomLevel
  } = data;

  const parseIdToNumber = (id: any) => Number(parseId(id, "-"));

  const transformLayers = (layers: any[], selectedIds: any) => {
    if (layers !== undefined && layers.length > 0) {
      return layers.flatMap((layer: any) =>
        selectedIds.includes(layer.id)
          ? [{ ...layer, id: parseIdToNumber(layer.id) }]
          : []
      );
    } else {
      return [];
    }
  };

  const selectedPoints = transformLayers(
    allCommunityMarkers,
    selectedPointsIdToHighlight
  );
  const selectedLayers = selectedDefaultLayers?.map((layer: any) => ({
    ...layer,
    id: parseIdToNumber(layer.id),
  }));

  const structuredData = {
    id,
    name: question.name,
    title: title ?? splitCamelCaseToString(question.name),
    type: question.type,
    description,
    isRequired,
    selected_points: selectedPoints,
    center_point: [
      mapCenterPoint?.longitude ?? 0,
      mapCenterPoint?.latitude ?? 0,
    ],
    zoomLevel: zoomLevel,
    select_multiple: ableToCheckMultiple,
    selected_layers: selectedLayers,
    elementId: uuidv4(),
    questionId: uniqueIdGenertorInNumber(),
    selected_options: selectedPoints.map((p) => p.id),
    selected_layers_ids: selectedLayers.map((l: any) => l.id)
  };

  return structuredData;
}

export { convertJsonOfPointPicker };
